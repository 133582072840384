.particulier-panier-titre-item {
  color: #1c1c1c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.particulier-panier-item-infos-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.particulier-panier-image-item-container {
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  background: #f7f7f7;

  display: flex;
  width: 80px;
  height: 84px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.particulier-panier-btn-annuler-item {
  border-radius: 6px;
  border: 1px solid #dee2e7;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);

  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;

  color: #e9421e;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-btn-annuler-item:hover {
  background: #e9421e;
  color: #fff;
}
.particulier-panier-btn-ajouter-item {
  border-radius: 6px;
  border: 1px solid #dee2e7;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  color: #ed646e;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-btn-ajouter-item:hover {
  background: #ed646e;
  color: #fff;
}
.particulier-panier-btn-annuler-item-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 0px;
}
.particulier-panier-image-item {
  width: 72px;
  height: 75px;
  object-fit: cover;
}
.particulier-panier-prix-item {
  color: #1c1c1c;
  text-align: right;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.input-panier-form-add {
  border: 1px solid #dee2e7 !important;
  background: #fff !important;
  color: #1c1c1c !important;
  font-family: "DM Sans" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: 123px !important;
  height: 42px !important;
}

.qat_panier {
  border: 1px solid #dee2e7 !important;
  background: #fff !important;
  color: #1c1c1c !important;
  font-family: "DM Sans" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  width: auto !important;
  height: 42px !important;
  border-radius: 5%;
  padding: 5%;
}

.qat_panier span {
  cursor: pointer;
}
.particulier-panier-quantite-item-container {
  display: flex;
  justify-content: flex-end;
}
.particulier-list-group-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-left: none;
  border-right: none;
  border-radius: 0px;
}
.particulier-list-group-item:first-child {
  border-top: none;
}
.particulier-list-group-item:last-child {
  border-bottom: none;
}
.particulier-list-group-item .form-check-input {
  width: 30px;
  height: 30px;
}
.particulier-panier-label-select-all {
  color: #1c1c1c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-btn-retour-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.particulier-panier-btn-retour {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 6px;
  background: #ed646e;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-btn-retour:hover {
  color: #ed646e;
  background: #fff;
  border: 1px solid #ed646e;
}
.particulier-panier-btn-tout-enlever {
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 6px;
  border: 1px solid #dee2e7;
  background: var(--white, #fff);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #e9421e;
}
.particulier-panier-btn-tout-enlever:hover {
  background: #e9421e;
  color: #fff;
}
.panier-particulier-list-item-container {
  border-radius: 10px;
  border: 1px solid #dee2e7;
  background: #fff;
  padding: 12px 8px;
}
.particulier-panier-infos-bon-label {
  color: #505050;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.particulier-panier-infos-bon-form-control {
  border: 1px solid #e0e0e0;
  color: #000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 10px;
}
.particulier-panier-infos-bon-form-control::placeholder {
  color: #8b96a5;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.particulier-panier-infos-bon-btn-add {
  border: 1px solid #e0e0e0;
  color: #ed646e;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-infos-bon-btn-add:hover {
  background: #ed646e;
  color: #fff;
}
.particulier-panier-infos-bon-container {
  border: 1px solid #dee2e7;
  padding: 15px 10px;
  border-radius: 10px;
}
.particulier-panier-infos-paiement-label {
  color: #505050;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.particulier-panier-infos-paiement-value {
  color: #505050;
  text-align: right;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.particulier-panier-infos-paiement-label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.particulier-panier-infos-paiement-value-2 {
  color: #fa3434;
  text-align: right;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.particulier-panier-infos-paiement-value-3 {
  color: #ed646e;
  text-align: right;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.particulier-panier-infos-divider {
  border-bottom: 1px solid #e4e4e4;
}

.particulier-panier-infos-form-check .form-check-input:checked {
  background-color: #000;
  border-color: #000;
}
.particulier-panier-infos-form-check .form-check-label {
  color: #000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.particulier-panier-infos-label-total {
  color: #1c1c1c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.particulier-panier-infos-value-total {
  color: #1c1c1c;
  text-align: right;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.2px;
}
.particulier-panier-infos-label-total-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.particulier-panier-infos-paiement-btn-paiement {
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 15px 0px;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer !important;
}
.particulier-panier-infos-paiement-btn-paiement:hover {
  color: #1c1c1c !important;
}
.panier-particulier-infos-service-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.panier-particulier-infos-service-title {
  color: #1c1c1c;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.panier-particulier-infos-service-texte {
  color: #a9acb0;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
}
.custom-form-check-input label.form-check-label {
  width: 175px;
}
.form-check-input.custom-form-check-input.mb-4.form-check {
  border: none;
}

/* ===================================================  */
.custom-check-payment-method ul {
  list-style-type: none;
}

.custom-check-payment-method li {
  display: inline-block;
}

.custom-check-payment-method input[type="radio"] {
  display: none;
}

.custom-check-payment-method label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.custom-check-payment-method label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -10px;
  left: -7px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
  z-index: 1;
}

.custom-check-payment-method label img {
  width: 34px;
  height: 22px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.custom-check-payment-method :checked + label {
  border-color: #ddd;
}

.custom-check-payment-method :checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

.custom-check-payment-method :checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
}
/* ===================================================  */
