.colgate_wrapper {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.10);
}

.cart_action_formatter {
    border-radius: 5px;
    background: linear-gradient(90deg, transparent 50%, #EB8020 0);
    background-size: 210% 100%;
    background-position: 100% 100%;
    height: 20px;
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: var(--Blanc, #FFF);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1.2px solid transparent;
    transition: .3s ease-in;
}

.cart_action_formatter:is(:hover, :focus) {
    color: #EB8020;
    background-position: 0 100%;
    border-color: #EB8020;
}

.colgate-card, .stock_product_details .colgate_wrapper {
    font-family: DM Sans;
    font-style: normal;
    line-height: normal;
    height: 100%;
}

.colgate-card .card__title, .colgate-card .sm__value, .stock_product_details .card__title {
    color: #3B4758;
}

.colgate-card .card__title, .stock_product_details .card__title {
    font-size: 18px;
    font-weight: 500;
}

.colgate-card .sm__value {
    font-size: 14px;
    font-weight: 400;
}

.csd_color {
    color: #ED646E !important;
}

.colgate-card .separator {
    width: 100%;
    height: .5px;
    background: #EEF0F4;
}

.colgate-card .payment_method img {
    width: 120px;
    height: 50px;
}

.colgate_wrapper .command_infos, .colgate_wrapper .top_title__container {
    justify-content: space-between;
    align-items: center;
}

.colgate_wrapper .command_id {
    color: #3B4758;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.colgate_wrapper .command_date {
    color: #586A84;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.colgate_wrapper .command_status {
    color: #7D8FA9;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.csd_color__wrapper {
    display: inline-flex;
    height: 18px;
    padding: 8px 5px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 9px;
    background: #FEFBF9;
    color: #ED646E;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bg_warn {
    background: #FFF2DA;
}

.options-container {
    justify-content: space-between;
}

.btn-theme-go-back {
    display: inline-flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    border: 1px solid #3B4758;
    background: #fff;
    color: #3B4758;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.finalize_steps .tabs-container {
    padding-left: 0;
}

.finalize_steps .tabs-container .tab-item {
    color: #7D8FA9;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
}

.finalize_steps .tabs-container .tab-item span {
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #7D8FA9;
    color: #fff;
}

.finalize_steps .tabs-container .tab-item-active {
    color: #000;
}

.finalize_steps .tabs-container .tab-item-active:after {
    display: none;
}

.finalize_steps .tabs-container .tab-item-active span {
    background: #ED646E !important;
}

.radio_check_content label {
    color: #3B4758;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

form p.custom_info_check {
    color: #586A84;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
}

.item_wrapper {
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid #EEF0F4;
    background:  #fff;
}

.item_wrapper img {
    width: 50px;
    height: 25px;
}

.colgate-card .recap_content {
    padding: 1.4rem 0;
}

.colgate-card .recap_content .edit_icon {
    position: absolute;
    top: 1.4rem;
    right: 0;
}

.colgate-card .edit_icon {
    background: transparent;
    padding: 4px;
    border-radius: 50%;
    color: #586A84;
    transition: .2s ease-in-out;
    cursor: pointer;
}

.colgate-card .edit_icon:is(:hover, :focus) {
    background: #ED646E;
    color: #fff;
}