.details-pro-admin-username {
    color: #23272E;
    font-family: "Public Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.details-pro-admin-user-email {
    color: #8B909A;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Public Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}
.details-pro-admin-user-avatar {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    object-fit: cover;
}
.details-pro-admin-user-infos-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.details-pro-admin-btn-rejeter {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    background: #E9421E;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-btn-rejeter:hover {
    color: #E9421E;
    border: 1px solid #E9421E;
}
.details-pro-admin-btn-accepter {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 6px;
    background: #ED646E;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-btn-accepter:hover {
    color: #ed646e;
    border: 1px solid #ED646E;
}
.details-pro-admin-btn-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.details-pro-admin-subtitle {
    color: #8B909A;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}
.details-pro-admin-autres-infos-label {
    color: #23272E;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-autres-infos-value {
    color: #23272E;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.details-pro-admin-autres-infos-label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.custom-border-x {
    border-left: 1px solid #DBDADE;
    border-right: 1px solid #DBDADE;
}
.details-pro-admin-text-message {
    color: #23272E;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-infos-produit-title {
    color: #ED646E;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.details-pro-admin-infos-produit-date-maj {
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-infos-produit-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
}
.details-pro-admin-infos-produit-stock-value {
    display: flex;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #3B4758;
    background: #FFF;
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-infos-produit-container {
    background: #FFF;
}
.details-pro-admin-commande-statut-en-cours-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ED646E;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    padding: 6px;
    border-radius: 9px;
    background: #FEFBF9;
}
.details-pro-admin-commande-paiement-container {
    color: #ED646E;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-pro-admin-commande-numero-container {
    color: var(--grey-700, #586A84);
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.details-pro-admin-commande-statut-complete-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #219653;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
    border-radius: 9px;
    background: rgba(111, 207, 151, 0.20);
    padding: 6px;
}
.details-pro-admin-commande-statut-annuler-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #E9421E;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
    border-radius: 9px;
    background: #FEFBF9;
    padding: 6px;
}
.details-pro-admin-commande-statut-livrer-container {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #388E3C;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background: none;
    border-radius: 9px;
    background: #E2F5EA;
    padding: 6px;
}