.catalogue_description {
    color: rgba(12, 12, 12, 0.40);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.promo-offer-card {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
    gap: 8.504px;
    font-family: DM Sans;
    font-style: normal;
    border-radius: 9px;
    background: linear-gradient(180deg, rgba(237, 100, 110, 0.50) 2.68%, rgba(237, 100, 110, 0.50) 100%);
}

.promo-offer-card p {
    color: #fff;
    font-size: 30.615px;
    line-height: 39.8px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.promo-offer-card span {
    font-size: 54.427px;
}

.promo-offer-card .btn-see-offer {
    display: inline-flex;
    padding: 8.504px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 25.513px;
    background: var(--light-colors-white, #fff);
    color: var(--rose-2, #ED646E);
    font-size: 13.607px;
    font-weight: 500;
    line-height: 13.607px;
    transition: .3s ease-in-out;
}

.promo-offer-card .btn-see-offer:is(:hover, :focus) {
    background: var(--rose-2, #ED646E);
    color: #fff;
}

.catalog-product-card {
    border: 1px solid #F6F8FB;
    font-family: DM Sans;
    font-style: normal;
    line-height: 23.8px;
}

.catalog-product-card .image_content {
    border-bottom: 1px solid #F6F8FB;
}

.catalog-product-card .promotion {
    display: grid;
    place-items: center;
    padding: 3px 6px;
    background: var(--orange, #EB8020);
    color: var(--light-colors-white, #FFF);
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    top: .8rem;
    left: 0;
}

.catalog-product-card .stock_status {
    border-radius: 4px;
    border: 1px solid var(--light-colors-platinum-2, #F6F8FB);
    background: var(--light-colors-white, #FFF);
    color: var(--extra-colors-bright-green, #00893A);
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    bottom: .4rem;
    left: .4rem;
    padding: 2px 5px;
}

.catalog-product-card img {
    width: 100%;
    object-fit: cover;
    height: 250px;
}

.catalog-product-card .increase_and_cart {
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.8px;
}

.catalog-product-card .custom_number_container {
    padding: 4px 10px;
    border-radius: 2px;
    border: 1px solid var(--light-colors-platinum, #EAECEE);
    background: var(--light-colors-white, #FFF);
    color: var(--dark-colors-rich-black, #0C0C0C);
}

.catalog-product-card .custom_number_container button {
    border: none;
    background: none;
}

.catalog-product-card .btn-add-to-cart {
    display: inline-flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: linear-gradient(90deg, transparent 50%, var(--rose-2, #ED646E) 0);
    background-size: 200% 100%;
    background-position: 100% 100%;
    color: var(--light-colors-white, #FFF);
    border: 1px solid var(--rose-2, #ED646E);
    transition: .3s ease-in-out;
}

.catalog-product-card .btn-add-to-cart:is(:hover, :focus) {
    background-position: 0 100%;
    color: var(--rose-2, #ED646E);
    border-color: var(--rose-2, #ED646E);
}

.catalog-product-card .old-price {
    color: var(--rouge, #E9421E);
    text-decoration: line-through;
}

.document-card {
    display: inline-flex;
    flex-direction: column;
    border: 1.131px solid rgba(0, 0, 0, 0.12);
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.06);
    background: #fff;
    text-decoration: none;
}

.document-card picture {
    display: inline-flex;
    justify-content: center;
    overflow-y: hidden;
}

.document-card picture img {
    width: 80%;
    height: 100px;
}

.document-card .title_content {
    border-top: 0.566px solid #D6D6D6;
    background: #F5F5F5;
    padding: .3rem 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.document-card .sm_title {
    color: #363636;
    font-family: DM Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}