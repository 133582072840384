.page-actu-card-item {
  border: 1px solid #f5f6fa;
  border-radius: 8px;
  height: 500px;
}
#card-actu-front-item.page-actu-card-item {
  height: inherit !important;
}
.page-actu-card-item-content-container {
  padding: 10px;
}
.page-actu-card-item-content-container.similaire {
  background: #fff;
}

.page-actu-card-item-content-infos-actu-container-type-lien {
  padding: 30px 10px;
}
.page-actu-card-item-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.page-actu-card-item-content-infos-actu-container,
.page-actu-card-item-content-infos-actu-container-type-lien {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-actu-card-item-content-infos-actu-user-container {
  display: flex;
  align-items: center;
  gap: 15px;
}
.page-actu-card-item-content-infos-actu-user {
  color: #ed646e;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
}
.page-actu-card-item-content-infos-actu-date {
  color: #aab0b7;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
}
.page-actu-card-item-content-infos-actu-like,
.page-actu-card-item-content-infos-actu-like-type-lien {
  display: flex;
  align-items: center;
  gap: 10px;
}
.page-actu-card-item-content-infos-actu-like span {
  color: #ed646e;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 183.333% */
}
.page-actu-card-item-content-title {
  color: #333;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 125% */
}
.page-actu-card-item-content-title.custom-title {
  color: #333;
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px; /* 120% */
}
.page-actu-card-item-content-description,
.page-actu-card-item-content-description p,
.page-actu-card-item-content-description h3,
.page-actu-card-item-content-description h4,
.page-actu-card-item-content-description h5,
.page-actu-card-item-content-description h6 {
  color: #858a8f;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.page-actu-card-item-content-btn {
  background: none;
  border: none;
  color: #e9421e;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.page-actu-card-item-content-infos-actu-user-type-lien {
  color: #ed646e !important;
  font-family: "Inter" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 22px !important; /* 122.222% */
}
.page-actu-card-item-content-infos-actu-date-type-lien {
  color: #aab0b7;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
}
.page-actu-card-item-content-infos-actu-like-type-lien {
  color: #ed646e;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
}
.page-actu-card-item-content-infos-actu-container-type-lien-icon-container {
  padding-top: 50px;
}
.page-actu-card-item-content-link {
  color: #3b4758;
  font-family: "DM Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  opacity: 0.6;
}
.casadeliz-section-hero-details-actu-container {
  height: 550px;
  padding: 10%;
  position: relative;
}
.casadeliz-section-hero-details-actu-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.casadeliz-section-hero-details-actu-btn-lire {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.casadeliz-section-hero-details-actu-badge {
  background: #fff;
  height: 37.664px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: #ed646e;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
  padding: 5px;
}
.casadeliz-section-hero-details-actu-title {
  color: #fff;
  font-family: "DM Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 112% */
}
.casadeliz-section-hero-details-actu-user-avatar {
  border-radius: 50%;
  width: 75px;
  height: 75px;
}
.casadeliz-section-hero-details-actu-user {
  display: flex;
  align-items: center;
  gap: 12px;
}
.casadeliz-section-hero-details-actu-username {
  color: #fff;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
.casadeliz-section-hero-details-actu-occupation {
  color: #aab0b7;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
}
.casadeliz-section-hero-details-actu-title-container {
  width: 80%;
}
.details-actu-content,
.details-actu-content p {
  color: #858a8f !important;
  font-family: "DM Sans" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
}
.details-actu-content h4,
.details-actu-content h3 {
  color: #3b4758 !important;
  font-family: "DM Sans" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.details-article-section-share-container {
  border: 1px solid #f5f6fa;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.details-article-section-share-title {
  color: #333;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
.details-article-section-share-icon-container {
  display: flex;
  align-items: center;
  gap: 30px;
}
.details-article-section-share-icon {
  color: #aab0b7;
  font-size: 30px;
}
.details-article-section-comment-title {
  color: #333;
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px; /* 121.875% */
}
.casadeliz-commentaire-item-infos-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.casadeliz-commentaire-item-text,
.casadeliz-commentaire-item-text p {
  color: #858a8f;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 166.667% */
}
.casadeliz-commentaire-item-btn-response,
.casadeliz-commentaire-item-btn-response:hover,
.casadeliz-commentaire-item-btn-response:focus {
  color: #e9421e;
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
  background: none;
  border: none;
  padding-left: 0px;
}
.casadeliz-section-hero-details-actu-username-forum {
  color: #333;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.casadeliz-section-hero-details-actu-occupation-forum {
  color: #aab0b7;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}
.casadeliz-section-hero-details-actu-user-avatar-forum {
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.custom-reponse-comment-container {
  padding-top: 3rem;
  padding-left: 4rem;
  border-left: 4px solid #f5f6fa;
}
.custom-forum-titre-reponse {
  color: #333;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px; /* 121.875% */
}
.form-control-register-forum {
  border-radius: 0px;
  border: 1px solid #f5f6fa;

  border-radius: 5px;
  padding: 15px !important;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.06em;
  color: #000000;
}
.form-control-register-forum::placeholder {
  color: #aab0b7;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.form-reponse-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-reponse-btn {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #ed646e;
}
.form-reponse-check-label label {
  color: #858a8f;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}
.custom-bloc-response-comment {
  padding-top: 3rem;
  padding-left: 6rem;
}
.custom-section-divider-forum {
  margin-bottom: 5rem;
}
.details-actu-article-similair-container {
  background: rgb(245, 246, 250);
  padding: 20px;
}
.details-actu-article-similair-title {
  color: #333;
  text-align: center;
  font-family: "DM Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 73px; /* 121.667% */
}
.details-article-video-titre {
  color: #000;
  font-family: "DM Sans";
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}
.details-article-video-text {
  color: #858a8f;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}
.details-article-video-titre-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.custom-video-archive {
  height: 190px;
}
.custom-video-article-container .react-player__preview {
  position: relative;
}
.custom-video-article-container svg {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
}
.details-article-audio-text {
  color: #14142b;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.custom-img-cover-article-audio {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.rhap_additional-controls,
.rhap_volume-controls {
  display: none !important;
}
.rhap_container {
  width: 100% !important;
  min-width: 300px !important;
  background: #7d8fa9 !important;
}
.custom-audio-player-container {
  position: absolute;
  bottom: -38px;
  left: -98px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.custom-video-audio-container svg {
  width: 45px;
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: #ed646e !important;
}
.rhap_current-time,
.rhap_total-time {
  color: #fff !important;
}
.rhap_play-pause-button {
  width: 70px !important;
  height: 72px !important;
}
.rhap_play-pause-button {
  position: absolute;
  left: -65px;
  top: -14px;
}
.container-type-actu-card-item-front {
  height: 250px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
