@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Oleo+Script:wght@400;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("./components/HomePage/HomePage.css");
@import url("./components/shared/FrontFooter/FrontFooter.css");

.no-link,
.no-link:hover,
.no-link.active {
  text-decoration: none;
  color: inherit;
  border: inherit !important;
}

.form-select-modal-custom {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  background: #fafafa !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 15px !important;
  padding-right: 20px;
  border: 1px solid #e2e8f0 !important;
  padding-right: 20px !important;
  /* background: url(./assets/icons/chevrondown.png) 96% 50% / 13px no-repeat #fff !important; */
  height: 58px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  border-radius: 10px !important;
}
._2Jtxm {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}
._2JvrO svg {
  font-size: 10px !important;
  width: 15px !important; 
  height: 15px !important;
}

.easy-market-alert {
  border-left: 6px solid #E36F31;
  border-radius: 0.4rem;
  color: #09132d;
  padding: 2rem 1%;
  display: flex;
  align-items: center;
  gap: 5px;
}

.easy-market-alert-info {
  background-color: rgba(227, 111, 49, 0.08);
}

.easy-market-alert h4 {
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 18px;
}
.pagination {
  position: relative;
  align-items: center;
  margin-top: 40px;
  justify-content: center;
}
.page-item.active .page-link {
  background-color: #e42d29!important;
  border-color: #e42d29!important;
  color: #fff !important;
  font-weight: 900;
}

li.page-item a {
  color: #000 !important;
}

.page-item:first-child .page-link span {
  color: #000 !important;
}

.page-item.disabled .page-link span {
  color: #000 !important;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  color: rgba(0, 0, 0, 0.5);
}

.swal2-backdrop-show {
  z-index: 999999 !important;
}
.item-c {
  display: flex;
  align-items: center;
}
.flex-c {
  display: flex;
  justify-content: center;
}