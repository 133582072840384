footer {
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.10);
  background: var(--Blanc, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: DM Sans;
  font-style: normal;
}

footer .custom-footer-title {
  color: var(--Text, #3B4758);
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

footer .custom-footer-link-value {
  color: var(--text2, #7D8FA9);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px; 
  text-decoration: none;
  padding-bottom: 7px;
}

footer .icon-wrapper {
  width: 32px;
  height: 32px;
  background: #7D8FA9;
  border: 1.2px solid transparent;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: #fff;
  transition: all .4s ease;
}

footer .icon-wrapper:hover {
  color: #ED646E;
  background: transparent;
  border-color: #ED646E;

}

footer .copyright-content {
  padding: 1.6rem 0;
  border-top: 1px solid var(--text2, #7D8FA9);
  background: var(--gray-200, #EFF2F4);
}