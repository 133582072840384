.form-control-input-search {
    border-radius: 4px;
    border: 1px solid #C8C7C7;
    background: #FFF;
    color:  #9A9AB0;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.08px;
    padding: 12px 30px;
    width: 300px;
}
.span-icon-search-header {
    position: absolute;
    right: 8px;
    top: 8px;
}
.container-nav-particulier-header {
    gap: 25px;
}
.btn-dropdown-category {
    color:  #3B4758;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.08px;
    background: transparent;
    border: 0;
    padding: 12px;
}
.btn-dropdown-category:hover, .btn-dropdown-category:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    color: #3B4758 !important;
}
.counter-notif-panier {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #ED646E;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.08px;
    position: absolute;
    top: -9px;
    right: 0;
}