.page-faq-title {
    color: #252A34;
    font-family: "DM Sans";
    font-size: 39px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 128.205% */
    text-transform: capitalize;
}
.page-faq-sub-title {
    color: var(--rose-2, #ED646E);
    font-family: "Lemon";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 233.333% */
    text-transform: capitalize;
}
.accordion-item,
.accordion-button:focus,
.accordion-button:not(.collapsed) {
    background: #FEFBF9;
    border: none;
}
.accordion-button {
    color: #252A34;
    font-family: "DM Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 132% */
    text-transform: capitalize;
}
.accordion-body {
    color: #333;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 168.75% */
}
.page-faq-besoin-aide {
    color: var(--rose-2, #ED646E);
    font-family: "Lemon";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 416.667% */
    text-transform: capitalize;
}
.page-faq-besoin-aide-text {
    color: #555;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.faq-card-info-aide {
    background: #FEFBF9;
    padding: 10px;
    height: 225px;
}
.faq-card-info-aide-title {
    color: #252A34;
    text-align: center;
    font-family: "DM Sans";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 33px; /* 132% */
    text-transform: capitalize;
}
.faq-card-info-aide-subtitle {
    color: #555;
    text-align: center;
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 109.091% */
    text-transform: capitalize;
}
.faq-card-info-aide-link {
    color: #2F80ED;
    text-align: center;
    font-family: "Montserrat";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration-line: underline;
}
.faq-details p {
    color: #252A34;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}