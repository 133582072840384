.tabs-container {
    display: flex;
    column-gap: 7px;
    list-style: none;
    padding-left: 0;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    border-radius: 6px 6px 0px 0px;
}

.with_border_bottom {
    border-bottom: 1px solid var(--light-solid-color-extra-divider, #DBDADE);
}

.tabs-container .tab-item {
    color: #8B909A;
    padding: 12px 1rem;
    position: relative;
}

.tabs-container .tab-item-active {
    color: var(--rose-2, #ED646E);
}

.tabs-container .tab-item-active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80%;
    height: 2px;
    background: var(--rose-2, #ED646E);
    margin: 0 auto;
}
