.add-produit-custom-subtitle {
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.add-produit-custom-label-indicator {
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.add-produit-custom-divider {
	border-bottom: 0.5px solid var(--grey-100, #eef0f4);
	padding-top: 20px;
	margin-bottom: 20px;
}
.add-produit-custom-statut-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.add-produit-custom-statut-label {
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.add-produit-custom-statut-badge {
	color: var(--success-600, #0da678);
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-radius: 9px;
	background: var(--success-100, #cafbec);
	display: flex;
	height: 18px;
	padding: 8px 3px;
	justify-content: center;
	align-items: center;
	gap: 3px;
}
.add-produit-custom-statut-badge.unavailable {
  background-color: #ed646e !important;
	color: #fff !important;
}
label.form-check-label.add-produit-custom-form-check-label {
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.form-check-input:checked {
	background-color: #ed646e;
	border-color: #ed646e;
	cursor: pointer;
}

.input-produit-label-form-add {
	color: #444150 !important;
	font-family: "DM Sans" !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important; /* 160% */
}
.input-produit-form-add {
	background: var(--Input_background, #f2f6fa) !important;
	border-radius: 6px !important;
	border: 1px solid #d9d9d9 !important;
	padding: 12px !important;
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	color: #444150 !important;
	margin-bottom: 5px;
}
.input-produit-form-add::placeholder {
	color: #3b4758 !important;
	font-family: "DM Sans" !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: normal !important; /* 160% */
	opacity: 0.5 !important;
}
.btn-add-autre-taille {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
	color: var(--grey-700, #586a84);
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.tagInputFieldClass {
	display: flex;
	padding: 12px 10px 12px 20px;
	align-items: center;
	align-self: stretch;
	border-radius: 6px;
	background: var(--Input_background, #f2f6fa);
	border: none;
	width: 100%;
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-bottom: 10px;
}
.tagInputFieldClass::placeholder {
	color: var(--grey-600, #7d8fa9);
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.tagClass {
	color: var(--grey-700, #586a84);
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: flex;
	height: 18px;
	padding: 8px 3px;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border-radius: 9px;
	background: var(--grey-100, #eef0f4);
}
.removeClass {
	color: #586a84;
	background: none;
	border: none;
	padding: 2px;
	width: 20px;
}
.add-produit-custom-custom-tag-input-indicator {
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.selectedClass {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}
.produit-editor-cgu .ql-container {
	min-height: 150px;
}
.add-produit-custom-btn-add-variation-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.add-produit-custom-btn-add-variation {
	display: flex;
	height: 40px;
	padding: 8px 10px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 6px;
	border: 1px solid var(--Text, #3b4758);
	background: var(--White, #fff);
	color: var(--Text, #3b4758);
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.detail-produit-img-produit {
	width: 100%;
	height: 318px;
	object-fit: cover;
}
.button-remove {
	background-color: transparent;
	border: none;
	padding: 0;
}
.drop-grid-img {
	height: 100px;
	width: 100px;
	object-fit: contain;
}
.del-drop-img {
	background-color: #ed646e;
	border: none;
	position: absolute;
	top: 10px;
	right: 10px;
	border-radius: 5px;
	padding: 1px 2px;
}
.grid-images {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
}
.variation-item {
	transition: all ease 500ms;
}
.stock-faible{
	background-color: #ed646e !important;
}