.profil_pink_banner {
    border-radius: 20px;
    background: #ED646E;
    padding: 7rem 0;
}

.profil_pink_banner .pro_edit_btn {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
    border-radius: 10px;
    background: linear-gradient(90deg, #ED646E 50%, #fff 0);
    background-size: 200% 100%;
    background-position: 100% 100%;
    backdrop-filter: blur(2px);
    color: #ED646E;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 13px 18px;
    border: 1.2px solid #ED646E;
    transition: .3s ease-in-out;
}

.profil_pink_banner .pro_edit_btn:is(:hover, :focus) {
    color: #fff;
    background-position: 0 100%;
    border-color: #fff;
}

.pro_profil_card {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.10);
    padding: 2rem 1rem;
    text-align: center;
    margin-top: -7rem;
    position: relative;
    z-index: 1;
}

.pro_profil_card img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.pro_profil_card .name {
    color: #000;
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.pro_right_card .sm-value {
    color: #3B4758;
    font-family: DM Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.pro_profil_card .sm-title, .pro_right_card .sm-title {
    color: #7D8FA9;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pro_profil_card .sm-title .icon {
    font-size: 22px;
}

.pro_right_card {
    padding: 1.5rem;
    border-radius: 10px;
    background: #fff;
}