.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}
.item-stat-dashboad-view {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  border-radius: 8px;
  border: 1px solid#E7EAEE;
  background: #fff;
}
.item-stat-dashboad-view-admin {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  border-radius: 12px;
  box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.10);
  background: #fff;
}

.titre-item-stat-dashboad-view {
  color: #72777B;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}
.chiffre-item-stat-dashboad-view {
  color: #2D2D2D;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.content-text-item-stat-dashboad-view {
  width: 100%;
}
.content-text-describe-stat-dash {
  color: #586A84;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-nombre-positif {
  color: #0A7D5A;
}
.text-nombre-negatif {
  color: #FF316A;
}
/* .admin-page-container-layout {
  margin-top: 90px;
} */
/* .content-stat-dashboard-view {
  display: flex;
  gap: 30px;
} */
.icon-item-stat-dashboad-view {
  display: flex;
  align-items: last baseline;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 12px;
}
.content-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-control-select-date-graph {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  /* background: url(../../../assets/icons/Shape.png) 96% 50% / 13px no-repeat
    #fff; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  width: 300px;
  padding: 15px;
  border: 0;
}
.chart-item-admin-title {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.container-title-content-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.content-graph-admin {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}
.text-subtitle-graph {
  font-family: "Lato";
font-size: 12px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0.10px;
color: #A0A4A8;
}
.chart-item-admin-subtitle {
  color:#3B4758;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}