.form-add-apropos-label {
	color: #000;
	font-family: "DM Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}
.btn-add-more-raison {
	display: flex;
	padding: 15px;
	justify-content: center;
	align-items: center;
	gap: 22px;
	color: #8b8b8b;
	font-family: "Ubuntu";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	background: #f1f1f9;
	width: 100%;
}
.option-faq-ol-custom-li {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 700;
	font-size: 19px;
	line-height: 28px;
	color: #3b4758;
}
.custom-page-actu-card-item-option {
	height: 295px;
	width: 100%;
	margin: auto;
}
.custom-flex-direction {
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
}
.custom-page-actu-card-item-img-option {
	height: 100%;
	border-radius: 8px 0px 0px 8px;
}
.option-details-actu-title {
	color: #3b4758;
	font-family: "DM Sans";
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 30px;
	display: flex;
	align-items: center;
	gap: 8px;
}
.option-details-actu-title-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0px;
}
.option-details-actu-btn-edit-container {
	display: flex;
	align-items: center;
	gap: 10px;
}
.option-details-actu-btn-edit,
.option-details-actu-btn-delete {
	color: #bb4411;
	background: none;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.option-details-actu-img {
	border-radius: 10px;
	width: 100%;
	object-fit: cover;
	max-height: 400px;
}
.option-details-actu-text,
.option-details p {
	color: #3b4758;
	font-family: "DM Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 194.7%;
}
.option-details-actu-titre,
.option-details h1,
.option-details h2,
.option-details h3 {
	color: #3b4758;
	font-family: "DM Sans";
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 77px;
}
.option-details-actu-autheur {
	color: #3b4758;
	font-family: "DM Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.45px;
}
.partener-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	gap: 1rem;
}

.partner-item__title {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
	color: #3b4758;
	margin: 0;
}
.partner-img {
	width: 80px;
	height: 80px;
	border-radius: 10px;
}
