.empty-fluidy {
  padding: 15px 20px 10px 23px;
  align-items: center;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.headering-admin {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-left: 18%;
  position: relative;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.1);
}
.logo-item {
  font-size: 25px;
  color: #000;
}
.logo-container.dashboard-header-logo img {
  width: 100%;
}

.container-menu-header-admin {
  display: flex;
  align-items: center;
}
.search-form-container {
  position: relative;
}
.navbar-header-trader {
  justify-content: space-around;
  width: 90%;
}
.user-name-connect {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
}
.custom-user-name-connect {
  color: #ffffff;
}
.notification-counter {
  background: #eb0508;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0%;
  right: 0%;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 22px;
  color: #ffffff;
}
.user-acount-dashboard-header-display {
  display: flex;
  align-items: center;
}
.user-acount-dashboard-header {
  appearance: none;
}
.dropdown-toggle::after {
  display: none;
}
.sent-message-header-container .btn:hover {
  border: none;
  border-color: transparent;
}

.header-hiddenable-section.animated {
  animation: grow 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.header-hiddenable-notification-section {
  position: absolute;
  right: 10%;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  top: 79%;
  width: 21%;
  z-index: 99;
}
.header-hiddenable-notification-section:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 20px solid #ffffff;
  position: absolute;
  right: 45%;
  top: -7%;
}
.header-hiddenable-notification-title {
  padding: 12px 17px;
  border-bottom: 1px solid #ececec;
  margin-left: -15px;
  margin-right: -15px;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
}
.header-hiddenable-notification-li {
  list-style: none;
  padding: 7px 15px;
  border-bottom: 1px solid #ececec;
  cursor: pointer;
}
.header-hiddenable-notification-ul {
  padding-left: 0;
  margin-left: -10px;
  margin-right: -10px;
}
.header-hiddenable-notification-li p {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
}
.header-hiddenable-notification-li:last-child {
  border-bottom: none;
}
.img-profil-notif {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.text-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 99.2%;
  color: #000000;
}
.text-date-notif {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 99.2%;
  color: #000000;
}
.header-hiddenable-notification-li:hover .notification-item-link {
  text-decoration: none !important;
  color: #003da5 !important;
}
.notification-item-link {
  text-decoration: none;
}
.notification-item-link:visited,
.notification-item-link:active,
.notification-item-link:focus {
  text-decoration: none !important;
  /* color: #02A69C !important; */
}
.container-nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.container-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
}
.sidebar {
  position: fixed;
  width: 280px;
  left: -280px;
  height: 100%;
  background-color: #fff;
  transition: all 0.5s ease;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  top: 74px;
  z-index: 9;
}

#check {
  display: none;
}
label #btn,
label #cancel {
  position: absolute;
  left: 5px;
  top: 23px;
  cursor: pointer;
  margin: 15px 30px;
  height: 23px;
  width: 29px;
  transition: all 0.5s ease;
}
label #cancel {
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ .sidebar {
  left: 0;
}
#check:checked ~ label #btn {
  margin-left: 245px;
  opacity: 0;
  visibility: hidden;
}
#check:checked ~ label #cancel {
  margin-left: 196px;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  color: white;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.content-nav-mobile {
  padding-top: 10%;
  padding-bottom: 10%;
  height: 600px;
  overflow-y: scroll;
}
.content-nav-mobile::-webkit-scrollbar {
  width: 6px;
}
.content-nav-mobile::-webkit-scrollbar-track {
  background: #fff;
}
.content-nav-mobile::-webkit-scrollbar-thumb {
  background-color: #ed646e;
  border-radius: 20px;
  border: 3px solid transparent;
}
.ReactModal__Overlay {
  background-color: rgb(157 157 157 / 75%) !important;
  z-index: 9999;
}

.text-reservation-ok {
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}
.search-form-container {
  position: relative;
}
.search-form-content label {
  position: absolute;
  top: 2px;
  left: 4%;
  color: #000;
  font-size: 20px;
}
input.form-control.search-input {
  width: 300px;
  padding: 10px 3rem;
  font-family: "Lato";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  color: #a0a4a8;
  border: 0;
  background: #fff;
  border-radius: 100px;
}
svg.search-icony {
  width: 18px;
  height: 18px;
  color: #828282;
}
.container-info-user-connect-header {
  display: flex;
  align-items: center;
  gap: 30px;
}
.name-user-connect-header {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #11142d;
}
.notif-icon {
  color: #cacccf;
  font-size: 20px;
}
.text-version-footer-admin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6182px;
  line-height: 22px;
  color: #000000;
}
.text-copyright-admin-footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12.0385px;
  line-height: 18px;
  color: #000000;
}
.footer-admin-conatiner {
  background: #f1f1f1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.admin-page-container-height {
  min-height: 90vh;
  margin-bottom: 100px;
  padding: 20px;
  width: calc(100% - 250px);
}
.custom-admin-page-container-height {
  min-height: 90vh;
  margin-bottom: 100px;
  padding: 20px;
  width: 100% !important;
}
#nav_button:checked
  ~ .page-content-base-container
  .admin-page-container-height {
  width: calc(100% - 120px);
}
#nav_button:checked
  ~ .page-content-base-container
  .custom-admin-page-container-height {
  width: 100% !important;
}

#header-user-admin .dropdown-menu {
  left: -120px;
}
#header-user-admin .menu-lang {
  left: -20px;
}
#header-user-admin .dropdown-item.active,
#header-user-admin .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #feaf14 !important;
}
