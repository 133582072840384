.about_us_bg {
    /* background-image: url('../../../assets/appImages/about_cover.png'); */
    background-color: #274C5B;
    padding: 7rem 0;
}

.custom-big-title {
    color: var(--Text, #3B4758);
    font-family: DM Sans;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.custom-small-title {
    color: var(--rose-2, #ED646E);
    font-family: Lemon;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-description, .custom-description p {
    color: #525C60;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 29.772px;
    text-align: justify;
}

.section_light {
    padding: 4rem 0;
    background: #F9F8F8;
}

.section_light .value-item .top-item-value {
    display: inline-flex;
    padding: 20px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 49px;
    background: #ECECEC;
    color: #274C5B;
    font-family: DM Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.value-item .description {
    color: #525C60;
    font-size: 13px;
    font-weight: 400;
    line-height: 29.772px;
    text-align: justify;
}

.section_light .value-card {
    padding: 2.5rem 1.5rem;
    background: #fff;
    display: grid;
    place-items: center;
    row-gap: 18px;
    position: relative;
    font-family: DM Sans;
    font-style: normal;
    line-height: normal;
    border-radius: 30px;
    position: relative;
    top: 0;
    transition: .4s ease;
}

.section_light .card-wrapper:hover .value-card {
    top: -12px;
}

.value-card .icon-content {
    width: 90px;
    height: 90px;
    border-radius: 18px;
    background: #F9F8F8;
    display: grid;
    place-items: center;
}

.value-card .title {
    color: var(--Text, #3B4758);
    font-size: 18px;
    font-weight: 700;
}

.value-card .description {
    color: #525C60;
    font-size: 15px;
    font-size: 18px;
    text-align: center;
}

.value-card .icon {
    position: relative;
}

.value-card .icon:after {
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    width: 29px;
    height: 29px;
    background: #F9D6D9;
}

.team-card {
    background: #F9F8F8;
    border-radius: 30px;
}

.team-card img {
    border-radius: 30px 30px 0 0;
    object-fit: cover;
    width: 100%;
    height: 260px;
}

.team-card .name {
    color: var(--Text, #3B4758);
    font-family: DM Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.team-card .role {
    color: #7EB693;
    font-family: Lemon;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.team-card a {
    text-decoration: none;
}

.page_section {
    padding: 6rem 0;
    position: relative;
}