.form-control-custom-devis {
  border: 1px solid #dee2e7;
  background: none;
}
.particulier-form-devis-fournisseur-container {
  background: linear-gradient(
      95deg,
      #ed646e 7.19%,
      rgba(237, 100, 110, 0) 89.49%
    ),
    url("../../../assets/appImages/form-fournisseur-bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 25px;
  width: 100%;
  margin: auto;
}
.particulier-form-devis-fournisseur-form-container {
    background: #FFF;
    filter: drop-shadow(0px 1px 2px rgba(56, 56, 56, 0.25));
    padding: 12px;
}
.particulier-form-devis-fournisseur-title {
    color: #FFF;
    font-family: "DM Sans";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
}
.particulier-form-devis-fournisseur-subtitle {
    color: #FFF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}
.particulier-form-devis-fournisseur-title-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
}
.particulier-form-devis-fournisseur-form-title {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.2px;
}
.particulier-form-devis-fournisseur-form-btn {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px 0px;
    background: #ED646E;
    color: #FFF;
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.particulier-form-devis-fournisseur-form-btn:hover {
    color: #ED646E;
    border: 1px solid #ED646E;
}
section.section-boutique.custom-section-divider {
    padding: 0px 100px;
}