.page-content {
  margin-top: 100px;
}
.section-banner-homepage {
  padding: 0 100px;
}
.container-home-page {
  background: #ffffff;
  padding: 30px 20px;
}
.container-easy-market {
  padding: 3rem 100px 0;
}
.title-home-page {
  color: #11142d;
  font-family: "Oleo Script";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 109%;
  letter-spacing: 0.192px;
}
.text-home-page-banner {
  color: #515151;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: 0.08px;
}
.btn-title-home-page {
  color: #ed646e;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.6%;
  border: 0.5px solid rgba(235, 128, 32, 0.4);
  background: #fff;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.img-col-right-banner-accueil {
  width: 100%;
}

.title-category-product-item {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  color: #333333;
}
.content-icon-category-product-accueil {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-product-accueil-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-list-category-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title-home-page-theme {
  font-family: "Roboto";
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  color: #333333;
}
.product-item-container {
  position: relative;
  background: #fff;
  width: 100%;
  border-radius: 23px;
  transition: all 0.2s ease-in-out;
}
.product-item-container:hover {
  /* transform: scale(1.1); */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.212);
}
.product-item-container:hover .img-product-item {
  transform: scale(1.01);
}
.statut-product-item {
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 20px 1px 1px 20px;
  background: #e58924;
  padding: 6px 20px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
}
.icon-fav-product {
  position: absolute;
  top: 257px;
  right: 2%;
  color: #e58924;
  font-size: 20px;
  width: 40px;
  height: 40px;
  box-shadow: 0px 4px 43px 0px #00000014;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.price-product-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}
.content-img-product-item {
  border: 1px solid #ededed;
  border-bottom: none !important;
  border-radius: 23px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.container-body-product-item {
  padding: 12px;
  border: 1px solid #ededed;
  border-top: none !important;
  border-radius: 23px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.name-prduct {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 38px;
  color: #001530;
}
.text-describe-product-item {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 13.344px;
  line-height: 23px;
  color: #000000;
  /* height: 80px;
  overflow-y: scroll; */
}
.img-product-item {
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 23px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.alice-carousel__stage-item {
  margin: 15px 25px !important;
}
.alice-carousel {
  position: relative;
}
.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: flex !important;
  box-sizing: border-box;
  width: 45px !important;
  height: 45px !important;
  padding: 0 !important;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0px 6px 30px 0px #0000003d;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  top: 40%;
  position: absolute;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  padding: 0 !important;
  color: #000 !important;
}
.alice-carousel__prev-btn {
  left: -62px;
}
.alice-carousel__next-btn {
  right: -62px;
}
.alice-carousel__dots-item:not(.__custom) {
  background-color: #c4c4c4 !important;
}
.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #e58924 !important;
}
.btn-see-more-product {
  font-family: "Inter";
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  color: #000;
}
.btn-see-more-product:hover {
  color: #e58924;
  font-weight: 700;
}
.container-testimonial-item {
  box-shadow: 0px 4px 100px 0px #afadb51a;
  background-color: #fff;
  padding: 15px;
}
.text-item-testimonial {
  font-family: "Inter";
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #afadb5;
}
.content-footer-testimonial {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-item-footer-testimonial {
  display: flex;
  gap: 10px;
}
.img-pp-testimonial {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.name-user-testimonial {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #151411;
}
.icon-rate-user-testimonial {
  color: #ffb23f;
}
.content-news-letter {
  background: #fefaf6;
  padding: 30px;
}
.title-news-letter {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: #333333;
}
.sub-title-news-letter {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #333333;
}
.form-control-news-letter {
  border: 1px solid #e8eaec;
  border-radius: 26px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #9a9a9a;
  background-color: #fff;
  padding: 15px 20px;
}
.btn-send-news-letter {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background: #e58924;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 20px;
  position: absolute;
  right: -15px;
  top: 0;
}
.content-col-left-select-product-bg {
  background-image: url(../../assets//appImages//bg-select.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  position: relative;
  padding: 30px 20px;
}
.content-btn-col-left-select-product-bg {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}
.container-cta-chat-produit {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 4px solid #b76102;
  border-radius: 4px;
}
.alice-carousel__wrapper {
  padding-bottom: 60px;
  padding-top: 60px;
}
.statut-product-item-rupture {
  background: #999999;
  position: absolute;
  right: 0;
  top: 20px;
  border-radius: 20px 1px 1px 20px;
  padding: 6px 20px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #fff;
}
.btn-theme-outline.disabled {
  color: #999999 !important;
}
.content-btn-achat-produit .btn-theme.disabled {
  background: #999999 !important;
  border: 0;
  color: #fff !important;
}

/* ============================================================ */
.home-page-titre-boutique {
  color: var(--light-text-color-title, #11142d);
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.24px;
  text-align: center;
}
.custom-btn-principal {
  display: inline-flex;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: var(--rose-2, #ed646e);

  color: var(--light-text-link-color-white, #fff);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.08px;
  border-radius: 0px;
}
.custom-btn-principal:hover {
  background: #fff;
  color: var(--rose-2, #ed646e);
  border: 0.5px solid var(--rose-2, #ed646e);
}
.custom-btn-principal-container {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
}
.custom-btn-nouveau {
  display: flex;
  width: 134px;
  height: 45px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 3px dashed var(--rouge, #e9421e);
  color: var(--rouge, #e9421e);
  font-family: "Poppins";
  font-size: 14.09px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.custom-btn-nouveau:hover {
  color: #fff;
  background: var(--rouge, #e9421e);
}
.section-nouveau-titre {
  color: #000;
  font-family: "Poppins";
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-btn-commander {
  display: inline-flex;
  padding: 18px 41px;
  align-items: flex-start;
  gap: 10px;
  background: var(--rose-2, #ed646e);
  box-shadow: -5px 12px 11px 0px rgba(233, 69, 43, 0.3);
  color: #fff;
  font-family: "DM Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.6%;
}
.custom-btn-commander:hover {
  color: var(--rose-2, #ed646e);
}
.section-nouveau {
  background: var(--Background, #fefbf9);
  padding: 3em 5em;
}
.casadeliz-card-temoignage-name {
  color: #525252;
  text-align: center;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.casadeliz-card-temoignage-occupation {
  color: #525252;
  text-align: center;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.casadeliz-card-temoignage-text,
.casadeliz-card-temoignage-text p {
  color: #525252;
  text-align: center;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.casadeliz-card-temoignage-user-infos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 4rem 1rem 3rem !important;
  margin-top: 3rem;
}

.casadeliz-card-temoignage.active {
  clip-path: polygon(7% 10%, 93% 0, 100% 83%, 0% 100%);
  transform: scale(1.05);
  border-radius: 60px 80px;
  margin-left: -60px !important;
}
.casadeliz-card-temoignage {
  clip-path: polygon(0 0, 100% 13%, 95% 100%, 9% 84%);
  background: #fff;
  border-radius: 60px 80px;
  transform-origin: 0 0;
  transform: rotate(-2deg);
  transform: scale(0.9);
  height: 405px;
}
.custom-slide-next .casadeliz-card-temoignage-user-infos-container {
  padding: 5rem 3rem 1rem 2rem !important;
}
.custom-slide-next .casadeliz-card-temoignage {
  clip-path: polygon(0 13%, 100% 0, 90% 87%, 5% 95%);
  background: #fff;
  border-radius: 80px;
  transform-origin: 0 0;
  transform: rotate(-2deg);
  transform: scale(0.9);
  height: 405px;
}

.casadeliz-card-temoignage-avatar-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.casadeliz-card-temoignage-avatar.active {
  left: 40% !important;
  transform: translateX(-50%);
}
.casadeliz-card-temoignage-avatar {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 7;
  top: -10%;
  left: 43%;
  transform: translateX(-50%);
  margin-bottom: 15px;
}
.swiper-wrapper {
  padding-top: 70px;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  top: 0px;
}
.swiper-button-next,
.swiper-button-prev {
  top: 25px !important;
}
span.swiper-pagination-bullet {
  background-color: #525252;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  transform: scale(1.1);
  background-color: #ed646e;
}
.casadeliz-card-temoignage-text {
  transform: rotate(2deg);
}
.slick-dots {
  top: -50px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #ed646e;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: #525252;
}
.custome-titre-temoignage {
  color: #525252;
  text-align: center;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-section-blog-title {
  color: var(--brand-color-primary, #10375c);
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.custom-section-blog-btn-voir {
  color: var(--rouge, #e9421e);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  background: none;
  border: none;
}
.custom-section-blog-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.section-blog-card-item {
  background: none;
  border-radius: 0px;
}
.section-blog-card-item-img {
  width: 100%;
  height: 250px;
}
.section-blog-card-item-description-container {
  padding: 15px;
}
.section-blog-card-item-description,
.section-blog-card-item-description p {
  color: var(--Text, #3b4758);
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section-blog-card-item-author {
  color: var(--text2, #7d8fa9);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 162.5% */
}
.section-blog-card-item-img-container {
  height: 200px;
  overflow: hidden;
}
.custom-section-partenaire-title {
  color: #1b3764;
  text-align: center;
  font-family: "Sofia Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 900;
  line-height: 120%; /* 54px */
  letter-spacing: -0.9px;
}
/* .logo-partenaire-casadeliz {
	width: 195px;
	height: 103px;
} */
.logo-partenaire-casadeliz-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 195px;
  height: 103px;
  object-fit: fill;
}
.logo-partenaire-casadeliz-container img {
  width: 100%;
  object-fit: fill;
}
.custom-section-contact-title {
  color: var(--light-text-color-title, #11142d);
  text-align: center;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
  letter-spacing: 0.24px;
}
.custom-section-contact-texte {
  color: var(--light-text-color-body-2, #9a9ab0);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  letter-spacing: 0.08px;
}
.custom-section-contact-input-group {
  border-radius: 8px;
  background: var(--light-primary-color-10, #f4f7f8);
  padding: 10px;
}
.custom-section-contact-form-control {
  background: none;
  border: none;
}
.custom-section-contact-form-control::placeholder {
  color: var(--light-text-color-body-2, #9a9ab0);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  letter-spacing: 0.08px;
}
.custom-section-contact-input-group-text {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 8px !important;
  background: var(--rose-2, #ed646e);
}
.custom-section-divider {
  margin-bottom: 8rem;
}
/* ============================================================ */
.custom-content-col-right-banner-accueil {
  margin-top: -60px;
}
.custom-spinner {
  width: 25px !important;
  height: 25px !important;
}
img.img-col-right-img-bouteille {
  width: 100%;
}

.custom-carroussel-row {
  display: flex;
  overflow: auto;
  flex-wrap: nowrap;
  gap: 30px !important;
}
/* .swiper-slide {
  width: 450px !important;
} */
a.section-blog-card-item {
  text-decoration: none;
}
