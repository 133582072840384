.custom-btn-caroussel.inactive {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #DEE2E7;
    background: #FFF;
    padding: 2px;
}
.custom-btn-caroussel.active {
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 3px;
    border: 1px solid #505050;
    background: #FFF;
    padding: 2px;
}
.custom-caroussel-details-produit .react-multi-carousel-dot-list  {
    display: flex;
    justify-content: center;
    gap: 10px;
}
.custom-caroussel-details-produit .react-multi-carousel-track {
    margin-bottom: 60px;
}
.particulier-details-produit-titre {
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.particulier-details-produit-prix {
    color: #E9421E;
    font-family: "DM Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.particulier-details-produit-texte {
    color: #525C60;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.4%;
}
.particulier-details-produit-titre-quantite {
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.particulier-details-produit-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.particulier-details-produit-btn-ajout-panier {
    border-radius: 15px 0px;
    background: #ED646E;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.input-particulier-details-produit-form-add {
    display: flex !important;
    padding: 10px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;
    width: 8rem !important;
    border-radius: 15px 0px !important;
    border: 2px solid #7D8FA9 !important;
    background: #FFF !important;

    color: #3B4758 !important;
    font-family: "DM Sans" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
}
.particulier-details-produit-section-tabs-container {
    border: 1px solid #DEE2E7;
    filter: drop-shadow(0px 1px 3px rgba(56, 56, 56, 0.10));
    background: #FFF;
    border-radius: 6px;
}
.particulier-details-produit-section-tabs-list-container {
    border-bottom: 1px solid #DEE2E7;
}
.particulier-details-produit-section-tabs-description-text {
    color: #505050;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.particulier-details-produit-section-tabs-description-table-container th,
.particulier-details-produit-section-tabs-description-table-container td {
    border: 1px solid #E0E7E9;
    color: #505050;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}
.particulier-details-produit-section-tabs-description-table-container th {
    background: #EFF2F4;
}
.particulier-details-produit-section-tabs-a-propos-titre {
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.particulier-details-produit-section-tabs-a-propos-text {
    color: #525C60;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.4%;
}

.apropos-custom-section .value-item .top-item-value {
    display: inline-flex;
    padding: 20px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 49px;
    background: #ECECEC;
    color: #274C5B;
    font-family: DM Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.value-item .description {
    color: #525C60;
    font-size: 13px;
    font-weight: 400;
    line-height: 29.772px;
    text-align: justify;
}

.apropos-custom-section .value-card {
    padding: 2.5rem 1.5rem;
    background: #fff;
    display: grid;
    place-items: center;
    row-gap: 18px;
    position: relative;
    font-family: "DM Sans";
    font-style: normal;
    line-height: normal;
    border-radius: 30px;
    position: relative;
    top: 0;
    transition: .4s ease;
}
.particulier-details-produit-titre-other-items {
    color: #1C1C1C;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.particulier-details-produit-titre-other-items-titre {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}
.particulier-details-produit-titre-other-items-prix {
    color: #8B96A5;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
}
.particulier-details-produit-titre-other-items-img-container {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}
.particulier-details-produit-titre-other-items-img {
    width: 100%;
    object-fit: cover;
}
.particulier-details-produit-titre-other-items-item {
    display: flex;
    gap: 12px;
    padding: 10px;
}