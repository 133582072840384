.cond-utilisation-ol-item-container {
	border: 1px solid rgba(204, 204, 204, 0.5);
	border-radius: 5px;
	margin-top: 10px;
	padding: 10px;
}
.cond-utilisation-ol-item-container p,
.cond-utilisation-ol-item-container span,
p.text-cgu-admin span,
.text-detail-message,
.text-detail-message p {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 209.9%;
	color: #000000;
}
.cond-utilisation-ol-custom-li::marker,
.cond-utilisation-ol-custom-li span {
	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 700;
	font-size: 19px;
	line-height: 28px;
	color: var(--rose-2, #ed646e);
}
.eglise-cond-utilisation-ol-container ol {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 19px;
	line-height: 28px;
	letter-spacing: 0.4px;
	color: #115c67;
}
.cond-utilisation-ol-custom-li {
	position: relative;
}
.eglise-cond-utilisation-ol-btn-container {
	position: absolute;
	right: 0px;
	/* bottom: 0px; */
	display: flex;
	gap: 5px;
	margin-top: 5px;
}
.eglise-cond-utilisation-ol-btn-delete,
.cond-utilisation-ol-btn-edit {
	border: none;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
}

.editor-cgu .ql-container {
	min-height: 150px;
}
.custom-ajout-chapitre-btn {
	width: 100%;
	background: #f1f1f9;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;

	font-family: "Ubuntu";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #8b8b8b;
}
.name-profil-grade-table {
	font-family: "Inter";
	font-size: 14px;
	font-weight: 500 !important;
	line-height: 17px;
	letter-spacing: 0em;
	color: #07add7 !important;
}

.eglise-cond-utilisation-ol-btn-container {
	position: absolute;
	right: 0px;
	/* bottom: 0px; */
	display: flex;
	gap: 5px;
	margin-top: 5px;
}
.eglise-cond-utilisation-ol-btn-delete,
.eglise-cond-utilisation-ol-btn-edit {
	border: none;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px;
}
.eglise-cond-utilisation-ol-btn-edit {
	color: #115c67;
}
.eglise-cond-utilisation-ol-btn-delete {
	color: #eb5757;
}

:is(
		.eglise-cond-utilisation-ol-btn-delete,
		.eglise-cond-utilisation-ol-btn-edit
	):hover {
	border: 1px solid #115c67;
}
.img-profil-itemt-table {
	width: 40px;
	height: 40px;
	border-radius: 6px;
}
.name-profil-patient-table {
	color: var(--Noir, #000);
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.custom-admin-table table thead tr th {
	color: var(--Noir, #000);
}
.input-parametre-label-form-add {
	color: #444150 !important;
	font-family: "DM Sans" !important;
	font-size: 15px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 24px !important; /* 160% */
}
.input-parametre-form-add {
	border-radius: 6px !important;
	border: 1px solid #d9d9d9 !important;
	background: #fff !important;
	padding: 12px !important;
	font-family: "Roboto" !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 24px !important;
	color: #444150 !important;
	margin-bottom: 8px;
}
.input-parametre-form-add::placeholder {
	color: #444150 !important;
	font-family: "DM Sans" !important;
	font-size: 15px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 24px !important; /* 160% */
	opacity: 0.5 !important;
}

.country-and-search-bar-content .search-bar-container input[type="search"],
.search-bar-container input[type="search"] {
	border-radius: 6px;
	background: var(--input-background, #f2f6fa);
	border: none;
	outline: none;
	padding: 8px 12px;
	width: calc(100% - 40px);
}

.country-and-search-bar-content .search-bar-container span,
.search-bar-container span {
	display: flex;
	width: 40px;
	height: 40px;
	padding: 8px 5px;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	background: var(--rose-2, #ed646e);
	color: #fff;
	font-size: 24px;
}
.country-and-search-bar-content {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 24px;
}
.css-13cymwt-control {
	background: var(--Input_background, #f2f6fa) !important;
}
.container-radio-input {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 20px 0;
}
.form-radio-input {
	display: flex;
	flex-wrap: wrap;
}
.radio-input label {
	margin-left: 10px;
	margin-right: 10px;
}
.radio-input label input {
	position: absolute;
	left: -9999px;
}
.radio-input label input:checked + span {
	background-color: #fff;
	border: 1px solid #ed646e;
	box-sizing: border-box;
	border-radius: 10px;
}
.radio-input label input:checked + span:before {
	box-shadow: inset 0 0 0 0.4375em #ed646e;
}
.radio-input label span {
	display: flex;
	align-items: center;
	padding: 0.375em 0.75em 0.375em 0.375em;
	border-radius: 10px;
	transition: 0.25s ease;
	border: 1px solid #e1e1e1;
	height: 45px;
	color: #586a84;
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.radio-input label span:hover {
	background-color: #fff;
	border: 2px solid #ed646e;
}
.radio-input label span:before {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	content: "";
	width: 1em;
	height: 1em;
	margin-right: 0.375em;
	transition: 0.25s ease;
	box-shadow: inset 0 0 0 0.125em #d9d9d9;
}
.radio-input label input:checked + span:before {
	content: "\2714";
	color: #fff;
	box-shadow: inset 0 0 0 0.4375em #ed646e;
}
.title-permissions {
	margin-bottom: 0;
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0;
}
