.liste-produit-accordion-header button.accordion-button {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.liste-produit-accordion-header .accordion-item, 
.liste-produit-accordion-header .accordion-button:focus, 
.liste-produit-accordion-header .accordion-button:not(.collapsed) {
    background: #FFF;
    border: none;
}
.liste-produit-accordion-body.accordion-body {
    background: #FFF;
    border: none !important;
    padding-left: 0px;
}

.liste-produit-accordion-list-group.list-group-item {
    color: #505050;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}
.cursor {
  cursor: pointer;
}
.cursor:hover {
  color: #ED646E;
}
.liste-produit-accordion-list-group-last-child.list-group-item {
    color: #ED646E;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}
.liste-produit-filtre-container .accordion-item {
    border-top: 1px solid #DEE2E7;
}
button.accordion-button {
    border-bottom: none !important;
}

/* Yellow Range Slider */
#range-slider-yellow {
    background: #FFF;
    border: .3px solid #eeeeee97;
  }
  
  #range-slider-yellow .range-slider__range {
    background: #ED646E;
    transition: height 0.3s;
  }
  
  #range-slider-yellow .range-slider__thumb {
    background: #FFF;
    transition: transform 0.3s;
    box-shadow: -5px 12px 11px 0px rgba(170, 169, 184, 0.30);
  }
  
  #range-slider-yellow .range-slider__thumb[data-active] {
    transform: translate(-50%, -50%) scale(1.25);
  }
  
  #range-slider-yellow .range-slider__range[data-active] {
    height: 16px;
  }

  .liste-produit-form-check-container label.form-check-label.add-produit-custom-form-check-label {
    color: #1C1C1C;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .liste-produit-form-check-container .form-check-input:checked {
    background-color: #ED646E;
    border-color: #ED646E;
  }
  .liste-produit-details-nbr-element {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6px;
    border: 1px solid #DEE2E7;
    background: #FFF;
    padding: 15px;
  }
  .liste-produit-details-card-produit {
    border: 1px solid #DEE2E7;
    border-radius: 5px;
    padding: 12px 2px;
  }
  .liste-produit-details-card-produit-image {
    width: 100%;
    object-fit: cover;
  }
  .liste-produit-details-card-produit-title {
    color: #1C1C1C;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .liste-produit-details-card-produit-price {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.2px;
  }
  .liste-produit-details-card-produit-price-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .liste-produit-details-card-produit-icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .liste-produit-details-card-produit-icon {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #DEE2E7;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(56, 56, 56, 0.08);
    color: #ED646E;
    padding: 5px;
    font-size: 20px;
  }
  .liste-produit-details-card-produit-infos-sup-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .liste-produit-details-card-produit-infos-sup-note-value {
    color: #FFC600;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .liste-produit-details-card-produit-infos-sup-note-value-container {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .liste-produit-details-card-produit-infos-sup-commentaire {
    color: #8B96A5;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .liste-produit-details-card-produit-infos-sup-icon-dot {
    background: #DEE2E7;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
  .liste-produit-details-card-produit-infos-sup-avertissement {
    color: #E9421E;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .liste-produit-details-card-produit-text {
    color: #505050;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
  .liste-produit-details-card-produit-btn-voir-plus {
    color: #ED646E;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }