.panier-modal-pas-de-compte-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.panier-modal-pas-de-compte-title {
  color: #0f172a;
  text-align: center;
  font-family: "Poppins" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.panier-modal-pas-de-compte-text {
  color: #64748b;
  text-align: center;
  font-family: "Poppins";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.2px;
}
.btn_logiques button {
  background-color: transparent !important;
  border: 1px solid #64748b !important;
}
.pt-5-content-page {
  padding-top: 3rem;
}

.message-stock-atteint{
  color: #ED646E;
  font-size: 13px;
}
