.footer-particulier {
    background: #FEFBF9;
    border: 0;
}
.custom-footer-title-particulier {
    color: #ED646E;
    font-family: "Roboto";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.title-info-contact-footer-particulier {
    color: #525C60;
    text-align: right;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 165%;
}
.custom-footer-link-value-particulier {
    color: #525C60;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 165%;
    text-decoration-line: underline;
    text-align: right;
}
.footer-particulier .icon-wrapper {
    background: transparent;
    color: #ED646E;
}
.custom-footer-link-value-item {
    color: #525C60;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 272.9%;
    text-decoration: none;
}
.custom-footer-link-value-item:hover {
    color: #ED646E;
}
.footer-particulier .copyright-content {
    background: #FEFBF9;;
    border-top: 1px solid rgba(237, 100, 110, 0.20);
    padding: 10px;
}
.footer-particulier .copyright {
    color: #525C60;
    text-align: center;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 165.4%;
}
.border-bloc-footer {
    border-left: 1px solid #ed646d28;
    border-right: 1px solid #ed646d28;
}