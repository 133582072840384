.filter-stock-and-search-bar-content {
    justify-content: space-between;
}

.bg_pink_blur {
    background: #FEFBF9;
    padding: 1rem;
}

.bg_pink_blur .sm-title {
    color: #0C0C0C;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.bg_pink_blur .sm-value {
    color: #0C0C0C;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.bg_pink_blur .sm-description-value, .sm-description-value p{
    color: #3B4758;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.detail-product-span {
    color: #ED646E;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 15px;
}
