.auth-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden auto;
    background: url(../../assets/appImages/auth_ellipse.svg) no-repeat;
    background-size: contain;
    background-position: 0 0;
    padding: 3rem;
}

.bg_blur {
    background-color: #FEFBF9;
}

.auth-wrapper .auth-card {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.10);
    padding: 2rem 1.4rem;
    font-family: DM Sans;
    font-style: normal;
    line-height: normal;
    margin-top: -4rem;
}

.auth-wrapper .half_content {
    width: 45%;
    margin: 0 auto;
}

.auth-card .top-title {
    color: #E9421E;
    font-size: 24px;
    font-weight: 700;
}

.auth-card .btn-social-connect {
    color: var(--Text, #3B4758);
    font-size: 11px;
    font-weight: 400;
    border-radius: 6px;
    border: 1px solid #7D8FA9;
    background: #fff;
    padding: 8px 14px;
    transition: .3s ease;
}

.auth-card .btn-social-connect:is(:hover, :focus) {
    border-color: #E9421E;
}

.auth-card .or-content {
    color: #3B4758;
    font-size: 14px;
    font-weight: 400;
    position: relative;
}

.auth-card .or-content:before, .auth-card .or-content:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 1px;
    background: #FEFBF9;
    width: 45%;
    margin: auto 0;
}

.auth-card .or-content:before {
    left: 5px;
}

.auth-card .or-content:after {
    right: 5px;
}

.auth-card label {
    color: #3B4758;
    font-size: 15px;
    font-weight: 500;
}
.auth-card .input-wrapper {
    position: relative;
}
span.span-eye-password-log {
    position: absolute;
    top: 40px;
    right: 20px;
    cursor: pointer;
}
.auth-card .input-wrapper input, .auth-card .input-wrapper textarea {
    padding: 12px 10px 12px 20px;
    border-radius: 6px;
    border: 1px solid #FEFBF9 !important;
    background-color: #fff !important;
    color: var(--text2, #7D8FA9);
    font-size: 14px;
    font-weight: 400;
}
.auth-card .input-wrapper input {
    border: 1px solid #aab0b742 !important;
}
.auth-card input:focus, .auth-card textarea:focus {
    border-color: #EB8020;
    box-shadow: none;
    outline: none;
}

.auth-card .remind-me label, .auth-card .forgot-password {
    color: #3B4758;
    font-size: 14px;
    font-weight: 400;
}

.auth-card .forgot-password {
    font-size: 11px;
    text-decoration: none;
}

.auth-card .btn-auth, .modal_pro_confirm_send .btn-auth {
    padding: 10px 12px;
    border-radius: 6px;
    background: linear-gradient(90deg, transparent 50%, #EB8020 0);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #EB8020;
    background-size: 210% 100%;
    background-position: 100% 100%;
    transition: .5s ease;
}

.auth-card .btn-auth:hover {
    color: #EB8020;
    background-position: 0 100%;
}

.auth-card .redirection {
    color: #3B4758;
    font-size: 12px;
    font-weight: 400;
}

.auth-card .redirection a {
    color: var(--orange, #EB8020);
    text-decoration: none;
}

.auth-card .custom-top-description {
    color: #586A84;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
}

.auth-card .input-wrapper .custom-code {
    color: #3B4758;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
}

.modal_title_pro {
    color: #ED646E;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; 
}

.modal_pro_confirm_send .icon_container {
    width: 80px;
    height: 80px;
    background: #EBF8F1;
    border-radius: 50%;
    display: grid;
    place-items: center;
}
.fas-text-success {
	color: #2eb75c !important;
}