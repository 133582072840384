button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

/* #navbarNav {
  margin-left: 180px;
} */
/*  */
.headering {
  background: #fff;
  box-shadow: 0px 4px 20px 0px #0000000d;
  width: 100%;
}
.container-select-lang #react-select-2-listbox img,
.selected-logo {
    width: 18px;
    height: 12px;
    margin-right: 10px;
}

.container-select-lang .css-13cymwt-control {
  border-color: transparent !important;
}

.container-select-lang.css-qbdosj-Input {
  color: #4f4f4f;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}


.firstheader-costum {
  padding: 20px 100px;
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  z-index: 99;
  background: #fff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 14%);
}

.dropdown-toggle::after {
  border-top: 0.2em solid !important;
  border-right: 0.2em solid transparent !important;
  border-left: 0.2em solid transparent !important;
  vertical-align: 2px !important;
}

.header-items-container {
  display: flex;
  align-items: center;
}

.header-container-fluid {
  padding-bottom: 15px;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav .navbar-btn-container .navbar-nav {
  display: flex;
  align-items: center;
}

.nav-item-btn-end {
  display: flex;
  align-items: center;
}

.connected-user-content .dropdown-toggle::after {
  display: none;
}

.header-navbar-contents-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-link {
  color: #3b4758 !important;
  font-family: "DM Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.085px;
}
.nav-item {
  padding: 0 25px;
}
.menu-link.active {
  border-bottom: 1px solid #ed646e;
}
.menu-link:hover,
.menu-link.active,
.menu-link:focus {
  color: #ed646e !important;
}
.menu-link svg {
  color: #ed646e;
}
.second-header-custom {
  padding: 10px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #faf3ee;
}

.text-second-header-custom {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  color: #333333;
  margin-bottom: 0;
  text-decoration: none;
}

.text-second-header-custom.btn:hover,
.text-second-header-custom.btn.active {
  color: #333333 !important;
  font-weight: 700;
  border: 0;
}

.content-left-second-header,
.content-right-second-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.content-left-second-header span {
  position: relative;
}

.b-b-1-white {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.bloc-flex-info-user-header {
  position: relative;
}
.categorie-btn-container {
  position: absolute;
  right: 14px;
  top: 14px;
}

.search-field {
  background: #d9d9d947;
  border-radius: 10px !important;
  width: 450px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #666666;
  padding: 17px 40px !important;
  border: 0;
}
.input-group-btn {
  position: absolute;
  left: 14px;
  top: 12px;
  color: #ed646e;
}
.categorie-btn-container .dropdown-toggle::after {
  display: none !important;
}

.counter-shop-notif {
  position: absolute;
  top: 1px;
  left: 5px;
  background: #fd0000;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 6px;
  color: #ffffff;
}

.input-group-search {
  display: flex;
  width: 100%;
}

.dropdown-menu-header-category {
  background: #ffffff;
  border-left: 1px solid #ed646e;
  border-radius: 10px;
}
.menu-link-category {
  color: #ed646e !important;
}
.menu-link-category:focus,
.menu-link-category.active {
  color: #ed646e !important;
  font-weight: 700;
  border: 0 !important;
}

.dropdown-item-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.dropdown-item-categorie,
.dropdown-item-categorie.active {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 206.4%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 10px;
  background-color: transparent;
}

.dropdown-item-categorie:hover {
  background: #e8f1fb;
  border-left: 5px solid #ed646e;
  color: #ed646e;
  font-weight: 700;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background: #e8f1fb;
  border-radius: 0px 10px 10px 0px;
  min-width: 200px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 12px 12px;
  z-index: 1;
}

#dropdown:hover .dropdown-content {
  display: block;
}

.sub-category-list {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 206.4%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #000000;
}

.content-info-category-header {
  background: #ffffff;
  border-radius: 9px;
  padding: 20px;
}

.ReactModal__Overlay--after-open {
  background-color: rgb(53 52 52 / 75%) !important;
  z-index: 9999;
  border: 0 !important;
}

.flex-r-header-modal {
  justify-content: flex-end !important;
}

.title-login-modal {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 44px;
  color: rgba(0, 0, 0, 0.85);
}

.text-resgister-modal-login {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #444150;
}

.btn-register-login-modal,
.btn-register-login-modal.active {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #e20613;
  border-color: transparent;
  background-color: transparent;
}

.btn-register-login-modal:hover {
  color: #e20613;
  text-decoration: underline;
  font-weight: 700;
}

.form-label-login-modal {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
}

.form-control-login-modal {
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150a5;
  padding: 15px;
}
.cartItem-container {
  max-height: 500px;
  overflow: auto;
}

.icon-heard-product {
  position: absolute;
  /* top: 20px;
    right: 12px;
    padding: 0; */
  border: 0;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0%;
  color: #666666;
  font-size: 20px;
}

.search-result-container {
  padding: 0px 15px;
  background: #fff;
  position: absolute;
  width: 100%;
  border: 1px solid #ececec;
  z-index: 999;
  border-radius: 10px;
}

.search-field:focus + .search-result-container {
  padding: 1rem 2rem;
  background: #fff;
  position: absolute;
  width: 100%;
  border: 1px solid #7b1cc32a;
  display: block;
}

.search-result-item {
  padding: 5px 0;
  display: block;
  color: #000000ef;
  font-family: "Raleway", sans-serif;
  transition: 0.2s ease-in-out;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
}
.container-btn-auth-accueil {
  display: flex;
  align-items: center;
  gap: 25px;
}
.btn-become-pro {
  color: #ed646e;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.07px;
  padding: 15px;
}
.btn-become-pro:hover {
  color: #ed646e;
  border: 1px solid #ed646e;
  background: #fff;
}
.btn-theme {
  background: #ed646e;
  color: #fff;
  font-family: "DM Sans";
  font-size: 16.109px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.081px;
  padding: 15px;
  transition: 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn-theme:hover {
  color: #ed646e;
  border: 1px solid #ed646e;
  background: #fff;
}
.selected-logo,
.country-logo {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.content-select-lang .css-13cymwt-control,
.content-select-lang .css-13cymwt-control:focus {
  border-color: transparent !important;
  border-radius: 6px;
  background: #faf3ee !important;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.13);
}
.content-select-lang #react-select-2-listbox img {
  width: 24px;
  height: 24px;
  margin-right: 13px;
}
.custom-cart-badge {
  background: #ed646e;
  color: #fff;
  font-family: "DM Sans" !important;
  font-size: 7px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.035px;
  width: 9.818px;
  height: 9.818px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  top: 0;
  right: -5px;
}
.custom-second-header-conatct-container {
  display: flex;
}
.header-rendu-front {
  padding: 10px 100px;
}
