.medsain-page-title {
  color: var(--rose-2, #ED646E);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.medsain-page-title-container {
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
}
.medsain-mon-profil-img-container {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(170, 169, 184, 0.1);
  border-radius: 12px;
  padding: 20px;
}
.medsain-mon-profil-img-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #3b4758;
}
.medsain-mon-profil-img {
  border-radius: 6px;
  object-fit: cover;
  width: 182px;
  height: 243px;
}

/* ============= CUSTOM TAB =============== */
.tabs-container-admin {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}
.tabs-container-admin li {
  list-style: none;
  display: flex;
  align-items: center;
  color: #828282;
  transition: all ease-in-out 300ms;
  padding-bottom: 10px;

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4b4c4d;
}
li.tab-active-item-admin {
  text-align: center;
  color: #ED646E !important;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.medsain-dash-tabs {
  background: #ffffff;
  border-radius: 0px 0px 12px 12px;
}

.tabs-component-container {
  background: #ffffff;
  border-radius: 12px 12px 0px 0px;
  padding: 16px 24px;
}
/* ============= END CUSTOM TAB =============== */
.medsain-profil-tab-title {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.auth-submit-btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.custom-btn-annuler {
  border-radius: 4px;
  padding: 10px;
  text-align: center;

  background: #eef0f4;
  border-radius: 6px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #586a84;
}
.custom-btn-submit {
  background: #07ADD7;
  border-radius: 4px;
  padding: 10px;
  text-align: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
/* ======== CUSTOM LIST ITEM =========== */
.medsain-profil-tab-item .list-group-item.non-lue {
  background: #efefff;
  border-radius: 10px 0px 0px 10px;
  border: none;
  padding: 12px;
}
.medsain-profil-tab-item .list-group-item {
  background: #ffffff;
  border-radius: 10px 0px 0px 10px;
  border: none;
  padding: 12px;
}
.medsain-profil-tab-item .custom-list-group-item {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  color: rgb(102, 102, 102);
}
.medsain-profil-tab-item .custom-list-group-item span {
  color: #000000;
}
.list-group-item-heure {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #586a84;
}
.list-group-item-badge {
  background: #FFF;
  border-radius: 4px;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  text-align: center;
  padding: 5px 10px;
  color: var(--rouge, #E9421E);
  letter-spacing: 0.096px;
}
/* ======== END CUSTOM LIST ITEM =========== */

/* ======== CUSTOM INPUT AVATAR ============ */
.leader-auto-user-avatar-container {
  position: relative;
}
.leader-auto-user-avatar-edit-hover {
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  width: 16%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.leader-auto-user-avatar-container:hover .leader-auto-user-avatar-edit-hover {
  opacity: 1 !important;
  cursor: pointer;
}
.input-add-avatar {
  display: none;
}
.input-testt {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pp-profil-user-visiteur {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
}

.workcare-dash-item-page-container {
  position: relative;
}

.leader-auto-user-avatar-edit-hover-deux {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 50%;
}
.leader-auto-user-avatar-container:hover
  .leader-auto-user-avatar-edit-hover-deux {
  opacity: 1 !important;
  cursor: pointer;
}
.leader-auto-user-avatar-edit-hover-deux-profil {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: #ffffff;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  border-radius: 6px;
}
.leader-auto-user-avatar-container:hover
  .leader-auto-user-avatar-edit-hover-deux-profil {
  opacity: 1 !important;
  cursor: pointer;
}
.edit-avatar-camera-icon {
  font-size: 26px;
}
/* ======== END CUSTOM INPUT AVATAR ============ */
.form-control-input-signature {
  background: #f1f1f9;
  border-radius: 2px;
  padding: 15px 45px;
  border: 0;
}
.content-icon-piece-jointe {
  position: absolute;
  left: 3%;
  top: 13px;
}
.icon-edit-info-profil {
  color: #4845e5;
  font-size: 20px;
  right: 12%;
  top: 37px;
  position: absolute;
}
