.input-details-livraison-form-add {
  border-radius: 4px !important;
  background: #f5f5f5 !important;
}
.input-details-livraison-label-form-add,
.input-details-livraison-form-add::placeholder {
  color: #000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.4;
}
.custom-detail-form-check.form-check-input:checked {
  background-color: #e9421e;
  border-color: #e9421e;
}
.custom-detail-form-label.form-check-label {
  color: #000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.custom-btn-confirm {
  border-radius: 15px 0px !important;
  background: #ed646e !important;
  padding: 12px 25px !important;
  color: var(--White, #fff) !important;
  font-family: "DM Sans" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.custom-btn-confirm:hover {
  color: #ed646e !important;
  border: 1px solid #ed646e !important;
  background: #fff !important;
}
.detail-livraison-titre {
    color: #1C1C1C;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.2px;
}
.detail-livraison-titre span {
    color: #BDBDBD;
}

/* ============= CUSTOM TAB =============== */
.tabs-container-particulier {
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .tabs-container-particulier li {
    list-style: none;
    display: flex;
    align-items: center;
    color: #828282;
    transition: all ease-in-out 300ms;
    padding-bottom: 10px;
  
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4b4c4d;
  }
  li.tab-active-item-particulier {
    text-align: center;
    color: #ED646E !important;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .medsain-dash-tabs {
    background: #ffffff;
    border-radius: 0px 0px 12px 12px;
  }
  
  .tabs-component-container {
    background: #ffffff;
    border-radius: 12px 12px 0px 0px;
    padding: 16px 24px;
  }
  .tab-items-title {
    color: #000;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  /* ============= END CUSTOM TAB =============== */

  .mon-compte-titre-editer {
    color: #E9421E;
    font-family: "DM Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }