.payment {
  font-family: "Poppins";
  border: 1px solid #f2f2f2;
  border-radius: 20px;
  background: #fff;
  padding-bottom: 20px;
  box-shadow: 0px 4px 20px 0px #0000000d;
}
.payment_header {
  background: #ed646e;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}
.error-payment_header {
  background: #e73628;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}
.icon-statut-payment {
  margin: 0px auto;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.check {
  color: #ed646e;
}
.close {
  color: #e73628;
}

.success-content,
.error-payment-content {
  text-align: center;
}

.success-content h1,
.error-payment-content h1 {
  font-size: 25px;
  padding-top: 25px;
}
.success-content p,
.error-payment-content p {
  color: rgba(0, 0, 0, 0.7);
}

.success-content a {
  color: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  color: #fff;
  background-color: #ed646e;
  transition: all ease-in-out 0.3s;
  text-decoration: none;
  border: 1px solid #ed646e;
}

.success-content a:hover {
  text-decoration: none;
  background: #fff;
  color: #ed646e;
}
.error-payment-content a {
  color: #fff;
  border-radius: 4px;
  padding: 10px 10px;
  color: #fff;
  background-color: #e73628;
  transition: all ease-in-out 0.3s;
  text-decoration: none;
  border: 1px solid #e73628;
}

.error-payment-content a:hover {
  text-decoration: none;
  background: #fff;
  color: #e73628;
}
