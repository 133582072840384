.item-sm-title {
  color: #e58924;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 37.6px;
  letter-spacing: -0.205px;
}

.item-sm-value {
  color: #060606;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 37.6px;
  letter-spacing: -0.205px;
}

.send-message-card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 1rem;
}

.send-message-card .card-title {
  color: #383838;
  text-align: center;
  font-family: Lato;
  font-size: 21px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.send-message-card .card-label {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.send-message-card .card-input,
.send-message-card .card-text {
  border-radius: 10px;
  background: #f4f4f4;
  padding: 8px 20px 8px 10px;
  outline: none;
  border: none;
}

.send-message-card .submit-btn {
  display: flex;
  width: 100%;
  padding: 16px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: var(
    --couleur-1,
    linear-gradient(308deg, #db242a 4.2%, #e58924 64.82%)
  );
  color: #fff;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-transform: uppercase;
  border: 1.3px solid transparent;
  transition: 0.4s ease;
}

.send-message-card .submit-btn:hover {
  border-color: #db242a;
  background: transparent;
  color: #db242a;
}

.custom-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.maps-container {
  padding-top: 5rem;
  margin-bottom: -3.4rem;
}

.maps-container .maps-frame {
  width: 100%;
}

/* ======================================================= */
.casadeliz-section-hero-page-title {
  color: var(--Blanc, #fff);
  font-family: "DM Sans";
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.casadeliz-section-hero-container {
  height: 350px;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 100%), url("../../../assets/icons/contact.svg"), lightgray 50% / cover no-repeat; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-contact-subtitle {
  color: var(--Text, #3b4758);
  font-family: "DM Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-contact-text-message {
  color: #525c60;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.4%; /* 29.772px */
}
.page-contact-card-item {
  border-radius: 16px;
  border: 1px solid #eee;
  background: #fff;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
}
.page-contact-card-item-title {
  color: var(--Text, #3b4758);
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-contact-card-item-email {
  color: var(--Blue-1, #2f80ed);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.4%; /* 29.772px */
  text-decoration-line: underline;
}
.page-contact-card-item-value {
  color: #525c60;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.4%; /* 29.772px */
}
.page-contact-titre-localisation {
  color: var(--rose-2, #ed646e);
  font-family: "Lemon";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.page-contact-titre-adresse {
  color: var(--Text, #3b4758);
  font-family: "DM Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-contact-card-localisation-titre {
  color: var(--Text, #3b4758);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 165.4%; /* 29.772px */
}
.page-contact-card-localisation-adresse {
  color: var(--Text, #3b4758);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 165.4%;
}
.page-contact-card-localisation {
  display: flex;
  align-items: center;
  gap: 10px;
}
.form-label-register-tabs {
  color: var(--Text, #3b4758);
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form-control-register-tabs {
  border-radius: 16px;
  border: 1px solid var(--text2, #7d8fa9);
  background: #fff;
  padding: 12px;
}
.custom-btn-send-contact {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #ed646e;
}
.custom-btn-send-contact:hover {
  color: #ed646e;
  border: 0.5px solid #ed646e;
  background: #fff;
}
/* ======================================================= */
