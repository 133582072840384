.custom-statut-active {
    display: flex;
    height: 18px;
    padding: 8px 10px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 9px;
    background: var(--success-100, #CAFBEC);
    color: var(--success-600, #0DA678);
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-client-admin-adress-livraison-value {
    color: #23272E;
    font-family: "Public Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.details-client-admin-adress-livraison-label {
    color: #8B909A;
    font-family: "DM Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.26px;
}
.details-client-admin-adress-livraison {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.btn-action-relancer-client {
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #ED646E;
    color: #FFF;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.btn-action-relancer-client:hover {
    color: #ED646E;
    border: 1px solid #ED646E;
}
.details-commande-infos-panier-contaier {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.details-commande-infos-panier-id {
    color: #3B4758;
    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.details-commande-infos-panier-date {
    color: #586A84;
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-commande-infos-panier-statut-label {
    color: #7D8FA9;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.details-commande-infos-panier-statut-value {
    color: #ED646E;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-commande-infos-panier-statut-label-container {
    display: flex;
    align-items: center;
    gap: 8px;
}
.details-commande-infos-panier-btn-relancer {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #ED646E;
    color: var(--White, #FFF);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.details-commande-infos-panier-btn-relancer:hover {
    color: #ED646E;
    border: 1px solid #ED646E;
}
.details-commande-infos-panier-title {
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.details-commande-table thead {
    border-bottom: 1px solid rgba(238, 240, 244, 0.6);
    height: 50px;
    background: #EEF0F4;
    color: #3B4758;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}