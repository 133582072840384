.btn-action-filter-icon,
.btn-action-filter-icon:hover {
  color: #7D8FA9;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-delete,
.btn-action-icon-delete:hover {
  color: #7D8FA9;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-icon-edit,
.btn-action-icon-edit:hover {
  color: #7D8FA9;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-action-modal-icon,
.btn-action-modal-icon:hover {
  color: #7D8FA9;
  border: none;
  background: #C2E9EC;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #158A91;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-modal-mail,
.btn-action-modal-mail:hover {
  color: #219653;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.btn-action-modal-mail::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #219653;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-filter-icon::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #EB8020;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.btn-action-icon-delete::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #fa1414;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}

.btn-action-icon-edit::after {
  content: attr(data-tooltip-content);
  padding: 8px;
  border-radius: 4px;
  position: absolute;
  top: 77%;
  right: 50%;
  left: 50%;
  width: max-content;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #C5135F !important;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, 18px) scale(0.8);
  transition: visibility, opacity, transform 200ms;
}
.with-tooltip {
  position: relative;
}
.with-tooltip:hover::after {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}
.statut-commande {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  padding: 15px;
}
.accepte {
  background: #effef8;
  color: #08bb90;
}
.annule {
  color: #eb5757;
  background: rgba(250, 20, 20, 0.1);
}

.admin-table thead {
  border-bottom: 1px solid rgba(238, 240, 244, 0.6);
  height: 50px;
}
.admin-table table thead tr th {
    color:  #7D8FA9;
    font-family: "DM Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  /* background: #e8e8e8c0; */
}
.admin-table table tbody tr td,
.admin-table table tbody tr td p {
  color: #586A84;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.admin-table .table thead tr th,
.admin-table .table tbody tr td {
  text-align: left;
  vertical-align: middle;
}
.admin-table .table tbody tr {
  background: #fff;
  border-radius: 0px;
  border-bottom: 1px solid rgba(238, 240, 244, 0.6);
  height: 70px;
}
.admin-table .table {
  border: transparent;
  --bs-table-bg: transparent !important;
}
.img-profil-praticient-table {
  border-radius: 6px;
  width: 20px;
  height: 20px;
}
.name-profil-table {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 18px;
  color: #3B4758 !important;
}
.name-profil-client-table {
  color: #3B4758 !important;
  font-family: "DM Sans";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}

.date-commandetable-formatter {
  color: #586A84 !important;
  font-family: "DM Sans";
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.item-price-content-table {
  color:#586A84 !important;
  font-family: "DM Sans";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.status-en-cours {
  color: #FFAA04;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 9px;
  background: #FFF2DA;
  padding: 5px;

}
.status-en-chemin {
  color: #129199;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 9px;
  background: #CAF6F9;
  padding: 5px;
}
.status-livre {
  color: #0DA678;
  font-family: 'DM Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 9px;
  background: #CAFBEC;
  padding: 5px;
}
.status-annule {
  color: #FF316A;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 9px;
  background: #FFE5EC;
  padding: 5px;
}
.name-profil-vente-table {
  color:  #1C1C1C !important;
  font-family: "Inter";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 22px;
}
.img-profil-produit-vente-table {
  width: 40px;
  height: 40px;
}