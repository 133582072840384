.shop .tabs-container {
	display: flex;
	align-items: center;
	justify-content: space-around;
	list-style: none;
	overflow: auto;
}

.shop .tab-item {
	color: var(--light-text-color-body-2, #9a9ab0);
	font-family: "DM Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 28.8px;
	letter-spacing: 0.12px;
}

.shop .tab-item-active {
	color: #ed646e;
}

.big_padding {
	padding: 5rem 0;
}

.product-card {
	font-family: DM Sans;
	font-style: normal;
	line-height: 16.8px;
	letter-spacing: 0.07px;
}

.product-card img {
	width: 100%;
	height: 303px;
	object-fit: cover;
}

.product-card .sm-description,
.catalog-product-card .sm-name-description {
	color: var(--Text, #3b4758);
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.07px;
	text-decoration: none;
}

.product-card .name {
	color: var(--light-text-color-title, #0d1986);
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0.16px;
	line-height: 33px;
}

.product-card .current-price,
.catalog-product-card .current-price {
	color: var(--rose-2, #ed646e);
	font-size: 18px;
	letter-spacing: 0.5px;
	font-weight: 700;
}

.product-card .old-price {
	color: var(--text2, #7d8fa9);
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 0.5px;
	text-decoration: line-through;
}

.product-card .detail-btn {
	width: 30px;
	height: 30px;
	background: #ed646e;
	color: #fff;
	border: 1.2px solid transparent;
	font-size: 19px;
	display: grid;
	place-items: center;
	border-radius: 50%;
	transition: 0.3s ease;
}

.product-card .detail-btn:hover {
	border-color: #ed646e;
	background: transparent;
	color: #ed646e;
}

.product-card .product_big_padding_overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 3rem 0.6rem;
	display: none;
	transition: 0.5s ease-in;
	border: 1px solid red;
}

.product-wrapper:hover .product-card .product_big_padding_overlay {
	display: block;
}

.product-card .product_overlay {
	background: #fff;
	opacity: 0.95;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
	border-radius: 1rem;
}

.product-card .action_wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #eb802029;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	font-size: 28px;
	color: #eb8020;
	cursor: pointer;
	padding: 10px;
}

.product-card .action_wrapper:hover .btn_action__overlay {
	display: block;
}

.product-card .action_wrapper .btn_action__overlay {
	background: #eb8020;
	color: #fff;
	display: none;
	width: 78px;
	height: 18px;
	border: none;
	font-size: 7px;
	font-weight: 600;
	position: absolute;
	top: -1.4rem;
	border-radius: 4px;
	opacity: 1;
}

.shop_bg {
	background-image: url("../../../assets/appImages/shop_cover.png");
	padding: 7rem 0;
}

.section__shop_detail .custom_product_title {
	color: var(--text-title-text, #333);
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 38.4px;
	letter-spacing: 0.096px;
}

.section__shop_detail .product_price {
	color: var(--light-secondary-color-50, #f86338);
	font-family: DM Sans;
	font-size: 32px;
	font-style: normal;
	font-weight: 700;
	line-height: 38.4px;
	letter-spacing: 0.096px;
}

.section__shop_detail .detail_title {
	color: var(--light-text-color-title, #11142d);
	font-family: DM Sans;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 28.8px;
	letter-spacing: 0.12px;
}

.section__shop_detail .product_details {
	color: var(--gray-600, #505050);
	font-feature-settings: "clig" off, "liga" off;
	font-family: DM Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: justify;
}

.section__shop_detail .custom_sm__title {
	color: var(--light-text-color-title, #11142d);
	font-family: DM Sans;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
	letter-spacing: 0.08px;
}

.section__shop_detail .product__number button {
	border: 1.2px solid transparent;
	background: transparent;
	border-radius: 50%;
	transition: 0.2s ease-in-out;
}

.section__shop_detail .product__number button:is(:hover, :focus) {
	border-color: #ed646e;
	color: #ed646e;
}

.section__shop_detail .btn_add_to_cart,
.section__shop_detail .btn_add_to_fav {
	padding: 12px 19px;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 500;
	border: 2px solid #ed646e;
	font-family: DM Sans;
	font-size: 15px;
	font-weight: 700;
	line-height: 19.2px;
}

.section__shop_detail .btn_add_to_cart {
	background: linear-gradient(90deg, transparent 50%, #ed646e 0);
	color: #fff;
	background-size: 210% 100%;
	background-position: 100% 100%;
	transition: 0.5s ease;
}

.section__shop_detail .btn_add_to_fav {
	background: linear-gradient(90deg, #ed646e 50%, transparent 0);
	color: #ed646e;
	background-size: 200% 100%;
	background-position: 100% 100%;
	transition: 0.3s ease;
	margin-right: 1rem;
}
.btn_add_to_fav.active {
	background-color: #ed646e !important;
	color: #fff !important;
}

.section__shop_detail .btn_add_to_cart:is(:hover, :focus) {
	background-position: 0 100%;
	color: #ed646e;
}

.section__shop_detail .btn_add_to_fav:is(:hover, :focus) {
	background-position: 0 100%;
	color: #fff;
}

.description_and_comments {
	font-family: DM Sans;
	font-style: normal;
	letter-spacing: 0.195px;
}

.description_and_comments .custom__title {
	color: #11142d;
	font-size: 28px;
	font-weight: 700;
	line-height: 46.8px;
}

.description_and_comments .custom__content,
.description_and_comments .custom__content p {
	color: #9a9ab0;
	font-family: DM Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.08px;
	text-align: justify;
}

.comment__item .avatar__wrapper,
.comment__item .avatar__wrapper img {
	width: 64px;
	height: 64px;
	border-radius: 50%;
}

.comment__item .avatar__wrapper img {
	object-fit: cover;
}

.comment__item .name {
	color: #11142d;
	font-size: 20px;
	font-weight: 700;
	line-height: 28.8px;
	letter-spacing: 0.12px;
}

.comment__item .date {
	color: #9a9ab0;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	letter-spacing: 0.08px;
}

.comment__item .comment_short {
	color: #515151;
	font-size: 13px;
	font-weight: 400;
	line-height: 18.2px;
	letter-spacing: 0.112px;
}

.post_response_form {
	font-family: DM Sans;
	font-style: normal;
}

.post_response_form .custom__title {
	color: #333;
	font-size: 25px;
	font-weight: 500;
	line-height: 39px;
}

.post_response_form input,
.post_response_form textarea {
	background: #fff !important;
	border: 1.3px solid #f5f6fa !important;
}

.post_response_form input:focus,
.post_response_form textarea:focus {
	box-shadow: none !important;
	outline: none !important;
}

.post_response_form .btn_post_comment {
	padding: 12px 19px;
	border-radius: 6px;
	background: linear-gradient(90deg, transparent 50%, #ed646e 0);
	color: #fff;
	font-size: 16px;
	font-weight: 500;
	border: 1px solid #ed646e;
	background-size: 200% 20%;
	background-position: 100% 100%;
	transition: 0.5s ease;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	line-height: 131.6%;
}

.post_response_form .btn_post_comment:hover {
	background-position: 0 100%;
	color: #ed646e;
}
.variation-item {
	display: flex;
	align-items: center;
	gap: 10px;
	color: #ed646e;
	font-family: "Roboto";
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	border: none;
	padding: 6px;
	border-radius: 9px;
	background: #fefbf9;
	border: 2px solid #ed646e;
}
.variation-item.active {
	color: #fff !important;
	background-color: #ed646e !important;
}
